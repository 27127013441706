import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FirebaseContext } from 'context';
import { ProtectedRoute, SEO, Numbers, ReferringUrls } from 'components';
import { scrollToAnchor } from 'utils';

const AnalyticsDashboard = ({ pageContext }) => {
  const { eid } = pageContext;
  const { firebase, user } = useContext(FirebaseContext);
  const [eventRegistrants, setEventRegistrants] = useState(0);
  const [companies, setCompanies] = useState([]);
  const [urlCount, setUrlCount] = useState({});

  useEffect(() => {
    let unsubscribeFromEventData;
    if (firebase) {
      unsubscribeFromEventData = firebase.moderatorDashboard.subscribeToEventData({
        eid,
        snapshot: (snapshot) => {
          if (!snapshot.empty) {
            setEventRegistrants(snapshot.size);
            const { docs } = snapshot;

            const _companiesList = [];
            const _urlCount = {};

            docs.forEach((doc) => {
              const { company, referringUrl } = doc.data();
              _companiesList.push(company);
              _urlCount[referringUrl] = _urlCount[referringUrl] + 1 || 1;
            });

            setCompanies(_companiesList);
            setUrlCount(_urlCount);
          }
        }
      });
    }
    return () => {
      if (unsubscribeFromEventData) {
        unsubscribeFromEventData();
      }
    };
  }, [firebase]);

  return (
    <ProtectedRoute isAdmin={user?.isAdmin} isModerator={user?.isModerator.includes(eid)}>
      <SEO pageSpecificTitle="Event Analytics" />
      <MenuTab
        initial={{ y: -120 }}
        animate={{ y: 0 }}
        transition={{ delay: 0.75, duration: 0.75 }}>
        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1.5, duration: 0.5 }}>
          Event Analytics
        </motion.span>
        <Anchors>
          <Anchor
            role="button"
            onClick={() => scrollToAnchor('the-numbers', { offset: -120, duration: 800 })}>
            The Numbers
          </Anchor>
          <Anchor
            role="button"
            onClick={() => scrollToAnchor('referring-urls', { offset: -120, duration: 1200 })}>
            Referring URLs
          </Anchor>
        </Anchors>
      </MenuTab>
      <Wrapper>
        <Numbers eid={eid} companies={companies} eventRegistrants={eventRegistrants} />
        <ReferringUrls urlCount={urlCount} />
      </Wrapper>
    </ProtectedRoute>
  );
};

const MenuTab = styled(motion.div)`
  align-items: center;
  background: #00004e;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  left: 0;
  max-height: 60px;
  min-height: 60px;
  position: absolute;
  top: 4rem;
  width: 100%;
  span {
    color: #ffffff;
    font-family: noto-sans, tahoma, sans-serif;
    font-size: 1.25rem;
    grid-column: 1 / 6;
    line-height: 1.5rem;
    padding: 0 1rem;
  }
  @media only screen and (min-width: 1150px) {
    grid-template-columns: repeat(12, 1fr);
    span {
      grid-column: 2 / 5;
      padding: 0;
    }
  }
`;

const Anchors = styled.div`
  display: none;
  @media only screen and (min-width: 1150px) {
    align-items: center;
    display: flex;
    gap: 3rem;
    grid-column: 6 / 12;
    justify-content: end;
  }
`;

const Anchor = styled.div`
  align-items: center;
  color: white-space;
  color: white;
  cursor: pointer;
  display: flex;
  font-family: noto-sans, tahoma, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  justify-content: center;
  letter-spacing: 0.5px;
  line-height: 3rem;
  margin-left: 5rem;
  padding-top: 0.125rem;
  text-align: center;
  transition: 0.5s;
  width: 100%;
  @media only screen and (min-width: 1024px) {
    font-size: 0.875rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    color: white;
    width: auto;
    border-bottom: 2px solid transparent;
  }
  &:hover {
    border-bottom: 2px solid white;
  }
  &.active {
    border-bottom: 2px solid transparent;
  }
`;

const Wrapper = styled(motion.div)`
  align-items: center;
  color: #004d9a;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  grid-column: 1 / 7;
  justify-content: center;
  margin: 9rem 0 2rem 0;
  width: 100%;
  @media only screen and (min-width: 1024px) {
    gap: 6rem;
    grid-column: 1 / 13;
    margin: 12.5rem 0 4rem 0;
  }
`;

export default AnalyticsDashboard;
